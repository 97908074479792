import { useState, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";

const AddCase = ({
  handleClickAddCaseModal,
  cases,
  handleAddCase,
  credits,
  setCredits,
}) => {
  const [gak, setGak] = useState("");
  const [year, setYear] = useState("2022");
  const [court, setCourt] = useState("ΕΙΡΗΝΟΔΙΚΕΙΟ ΑΘΗΝΩΝ");
  const [note, setNote] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");

  const courts = [
    "ΑΡΕΙΟΣ ΠΑΓΟΣ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΑΘΗΝΩΝ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΑΜΑΡΟΥΣΙΟΥ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΑΧΑΡΝΩΝ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΒΑΣΙΛΙΚΩΝ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΕΛΕΥΣΙΝΑΣ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΙΛΙΟΥ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΚΑΛΛΙΘΕΑΣ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΚΟΥΦΑΛΙΩΝ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΚΡΩΠΙΑΣ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΛΑΓΚΑΔΑ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΛΑΥΡΙΟΥ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΜΑΡΑΘΩΝΟΣ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΜΕΓΑΡΩΝ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΝΕΑΣ ΙΩΝΙΑΣ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΠΕΙΡΑΙΑ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΠΕΡΙΣΤΕΡΙΟΥ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΧΑΛΑΝΔΡΙΟΥ",
    "ΕΙΡΗΝΟΔΙΚΕΙΟ ΧΑΛΚΙΔΑΣ",
    "ΕΦΕΤΕΙΟ ΑΘΗΝΩΝ",
    "ΕΦΕΤΕΙΟ ΕΥΒΟΙΑΣ",
    "ΕΦΕΤΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ",
    "ΕΦΕΤΕΙΟ ΠΕΙΡΑΙΩΣ",
    "ΠΡΩΤΟΔΙΚΕΙΟ ΑΘΗΝΩΝ",
    "ΠΡΩΤΟΔΙΚΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ",
    "ΠΡΩΤΟΔΙΚΕΙΟ ΠΕΙΡΑΙΑ",
    "ΠΡΩΤΟΔΙΚΕΙΟ ΧΑΛΚΙΔΑΣ",
  ];
  const options = courts.map((e) => {
    let label = e;
    if (e.includes("ΑΡΕΙΟΣ")) {
      label = e.replace("ΑΡΕΙΟΣ", "ΑΡ.");
    }
    if (e.includes("ΕΙΡΗΝΟΔΙΚΕΙΟ")) {
      label = e.replace("ΕΙΡΗΝΟΔΙΚΕΙΟ", "ΕΙΡ.");
    }
    if (e.includes("ΕΦΕΤΕΙΟ")) {
      label = e.replace("ΕΦΕΤΕΙΟ", "ΕΦ.");
    }
    if (e.includes("ΠΡΩΤΟΔΙΚΕΙΟ")) {
      label = e.replace("ΠΡΩΤΟΔΙΚΕΙΟ", "ΠΡ.");
    }
    return { label, value: e };
  });

  // const gakRef = useRef();

  // useEffect(() => {
  //   gakRef.current.focus();
  // }, [])

  const handleChange = (event) => {
    setCourt(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (
        cases.find((e) => e.gak === gak && e.year === year && e.court === court)
      ) {
        setErrMsg("Έχετε ξανακαταχωρήσει την υπόθεση");
        return;
      }
      if (
        cases.length < 500 &&
        !cases.find(
          (e) => e.gak === gak && e.year === year && e.court === court
        )
      ) {
        await axiosPrivate.post(
          "/cases",
          JSON.stringify({ gak, year, court, note: note.substring(0, 50) }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        handleClickAddCaseModal();
        setCredits(credits - 30);
        handleAddCase({ gak, year, court, note: note.substring(0, 50) });

        // forceUpdate()
        //console.log(JSON.stringify(response));
      }
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  return (
    <section className="container_ac" onKeyDown={handleClickAddCaseModal}>
      <p
        ref={errRef}
        className={errMsg ? "errmsg" : "offscreen"}
        aria-live="assertive"
      >
        {errMsg}
      </p>

      <div className="close_addcase">
        <p onClick={() => handleClickAddCaseModal()}>❌</p>
      </div>
      {/* <span></span> */}
      {/* <h1>Sign In</h1> */}
      <form className="form" onSubmit={handleSubmit}>
        {/* <label htmlFor="username">Email:</label> */}
        <div className="input-container ic1">
          <input
            className="input"
            placeholder=" "
            type="number"
            id="gak"
            autoComplete="on"
            onChange={(e) => setGak(e.target.value)}
            value={gak}
            required
          />
          <div className="cut"></div>
          <label htmlFor="gak" className="placeholder">
            ΓΑΚ
          </label>
        </div>

        {/* <label htmlFor="password">Password:</label> */}
        <div className="input-container ic2">
          <input
            className="input"
            type="number"
            id="year"
            placeholder=" "
            autoComplete="on"
            onChange={(e) => setYear(e.target.value)}
            value={year}
            required
          />
          <div className="cut"></div>
          <label htmlFor="year" className="placeholder">
            Έτος
          </label>
        </div>
        <div className="input-container ic2">
          <select value={court} onChange={handleChange} className="input">
            {options.map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {/* <input
            className='input addcase'
            type="text"
            id="court"
            placeholder=' '
            autoComplete="on"
            onChange={(e) => setCourt(e.target.value)}
            value={court}
            required
          /> */}
          <div className="cut"></div>
          <label htmlFor="court" className="placeholder">
            Δικαστήριο
          </label>
        </div>
        <div className="input-container ic2">
          <input
            className="input"
            type="text"
            id="note"
            placeholder=" "
            autoComplete="on"
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
          <div className="cut"></div>
          <label htmlFor="note" className="placeholder">
            Σημείωση
          </label>
        </div>
        <button type="text" className="submit">
          Προσθήκη
        </button>
      </form>
    </section>
  );
};

export default AddCase;
